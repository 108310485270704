import React from "react";

import { Seo } from "~/components/seo";
import { Main } from "~/layouts";
import { HeroTitle } from "~/components/hero";
import { HeroSubheadline } from "~/components/typography";
import { MarketingBlock, MarketingCopy } from "~/components/marketing";
import { Footer } from "~/components/footer";

const FaqsPage = () => (
  <Main fullView>
    <Seo title="FAQ's" />
    <HeroTitle
      title="FAQ's"
      align="center"
    />

    <MarketingBlock>
      <HeroSubheadline>Shipping</HeroSubheadline>
    </MarketingBlock>
    <MarketingBlock>
      <MarketingCopy as="div">
        <strong>What shipping options do you offer?</strong>
        <p>We currently offer shipping through USPS. Cards shipped within the United States take around 3-7 days to arrive.</p>
        <p>Please allow 1 business day from the time of purchase for order processing.</p>
      </MarketingCopy>
      <MarketingCopy as="div">
        <strong>Can I track my card?</strong>
        <p>Snails are notoriously hard to put tracking devices on, so unfortunately, no. Since snail mail is sent via USPS First-Class Mail, tracking is not available.</p>
      </MarketingCopy>
      <MarketingCopy as="div">
        <strong>Why am I asked for my shipping after putting in a recipient address?</strong>
        <p>We understand it can be a bit confusing, but do not worry, your cards will be sent to the correct recipients. We need a shipping address for each sender as to prevent fraud. This address is only used when ordering blanks and cards you choose to send yourself.</p>
      </MarketingCopy>
      {/*<MarketingCopy as="div">
        <strong>Do you offer international shipping?</strong>
        <p>Yes! We currently offer international shipping. International orders that are shipped take around 7-10 days to arrive — so plan accordingly!</p>
        <p>If you have any questions, please reach out to <a href="mailto:hello@thegoodsnail.com">hello@thegoodsnail.com</a></p>
      </MarketingCopy>*/}
    </MarketingBlock>

    <MarketingBlock>
      <HeroSubheadline>Returns</HeroSubheadline>
    </MarketingBlock>
    <MarketingBlock>
      <MarketingCopy as="div">
        <strong>Do you accept returns?</strong>
        <p>All sales on The Good Snail are final. There are no returns or refunds.</p>
        <p>For inquiries or customer service issues, please reach out to <a href="mailto:hello@thegoodsnail.com">hello@thegoodsnail.com</a></p>
      </MarketingCopy>
    </MarketingBlock>

    <MarketingBlock>
      <HeroSubheadline>Payments</HeroSubheadline>
    </MarketingBlock>
    <MarketingBlock>
      <MarketingCopy as="div">
        <strong>What payment options are accepted?</strong>
        <p>The Good Snail accepts major U.S. credit cards (Visa, MasterCard, American Express, and Discover). Billing occurs at the time of or shortly after your transaction. You agree that you will only use your own credit card or have express permission to use another person’s credit card.</p>
      </MarketingCopy>
    </MarketingBlock>

    <MarketingBlock>
      <HeroSubheadline>Become a Seller</HeroSubheadline>
    </MarketingBlock>
    <MarketingBlock>
      <MarketingCopy as="div">
        <strong>How can I become a seller?</strong>
        <p>Here at The Good Snail, we love greeting card designers just as much as the cards themselves. We value each designer's art, which is why we proudly promote their label on the back of each card and feature descriptions of each designer on our site.</p>
        <p>If you are interested in licensing your work, please reach out to <a href="mailto:hello@thegoodsnail.com">hello@thegoodsnail.com</a></p>
      </MarketingCopy>
    </MarketingBlock>
    <Footer />
  </Main>
);

export default FaqsPage;
